<template>
  <div>
      <v-card class="mb-3">
        <v-card-text>Angebote werden gesucht, bitte warten.</v-card-text>
      </v-card>
  </div>
</template>

<script>
// Diese Komponente übernimmt beim direkten Laden einer GFO-NetzType-URL. 
// Wenn die GFO-URL's geladen sind (siehe plugins/gfoDynsmicRoutes) 
// wird ein Custom-Event auf dem document getriggert. 
// Dieser sorgt dafür dass der Router auf die gewünschte End-URL umleitet.
export default {
  name: "ResellerGfoNetzLoading",
  props: {
    netz_type: {
      type: String,
      required: true,
      default: null,
    },
  },
  created: function () {
    let netzType=this.netz_type;
    let that = this;
    document.addEventListener("gfoDynamicRoutesLoaded", function(){
      that.$router.push({ name: 'gfo-offer-' + netzType});
    });
  }
};
</script>

<i18n>
{
  "en": {
  },
  "de": {
  }
}
</i18n>

